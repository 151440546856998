/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {ArtikliModel, ParamsModelArtikli} from '../../models/ArtikliModel'
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import React from 'react'
import {deleteArtikli, getArtikli, rekalkulacijaArtikala} from '../../api/artikli'
import notifyToast from '../../components/Toast/Toast'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {Link, useNavigate} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import TablePagination from '../../components/TablePagination/TablePagination'
import ArtikliFilteri from '../../components/ArtikliFilteri/ArtikliFilteri'
import {PaginationModel} from '../../models/PaginationModel'
import {Modal, ModalHeader} from 'react-bootstrap'

const ArtikliLista: React.FC = () => {
    const [data, setData] = useState<ArtikliModel[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [image, setimage] = useState('')
    const [isModalOpen, setisModalOpen] = useState(false)
    const [params, setparams] = useState<ParamsModelArtikli>({per_page: 10, page: 1, aktivan: 1})

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')
    const [suma, setSuma] = useState(0)
    const [isSumaOpen, setisSumaOpen] = useState(false)

    // Sum of ArtPaletaTrenutnoStanje.
    const handleSuma = () => {
        var sum = 0
        table.getRowModel().rows.map((row) =>
            row.getVisibleCells().map((cell) => {
                if (cell.column.id === 'ArtStanjeSA') {
                    // @ts-ignore
                    sum += parseFloat(cell.getValue() ?? '0')
                }
            })
        )
        setSuma(sum)
    }

    // Set cell color according to condtions.
    const getCellColor = (cell, row) => {
        // Color for Tr.roba
        if (cell.column.id === 'ArtTrgRoba')
            return row.original.ArtTrgRoba === 0 ? '#FF0000' : '#00FF00'
        // Color default
        return '#FFFFFF'
    }

    const onImageClick = (value) => {
        setisModalOpen(true)
        setimage(value)
    }

    const updatePage = () => {
        setLoading(true)
        getArtikli(params)
            .then((response) => {
                const data = response.data['data']
                setData(data)
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const onFilter = (filter: Partial<ParamsModelArtikli>) => {
        setparams({
            per_page: pagination.perPage,
            page: 1,
            aktivan: filter.aktivan,
            artikal: filter.artikal,
            kategorija: filter.kategorija,
            roditelj: filter.roditelj,
        })
    }

    const columns = [
        {
            id: 'ArtSlika',
            header: 'ArtSlika',
            cell: ({row}) =>
                row.original.ArtSlika && (
                    <div onClick={() => onImageClick(row.original.ArtSlika)}>
                        {/* {row.original.ArtSlika} */}
                        <img
                            className='image-input-wrapper w-50px h-50px rounded'
                            // @ts-ignore
                            src={row.original.ArtSlika}
                            alt=''
                        />
                    </div>
                ),
        },
        {
             id: 'Id',
             header: 'Id',
             accessorKey: 'ArtId',
             cell: ({ row }) => (
                 <span style={{ color: row.original.ArtRoditelj === 0 ? 'red' : 'black' }}>
             {row.original.ArtId}
           </span>
             ),
         },
        {
            id: 'ArtSifra',
            header: 'Sifra',
            accessorKey: 'ArtSifra',
            cell: ({row}) => (
                <span style={{color: row.original.ArtRoditelj === 0 ? 'red' : 'black'}}>
            {row.original.ArtSifra}
          </span>
            ),
        },
        {
            id: 'Naziv',
            header: 'Naziv',
            accessorKey: 'ArtNaziv',
            cell: ({row}) => (
                <span style={{color: row.original.ArtRoditelj === 0 ? 'red' : 'black'}}>
            {row.original.ArtNaziv}
          </span>
            ),
        },
        {
            id: 'jedinica',
            header: 'Jedinica Mjere',
            accessorKey: 'jedinica.JmNaziv',
        },
        {
            id: 'ArtStanjeSA',
            header: 'Stanje Sarajevo',
            accessorKey: 'ArtStanjeSA',
        },
        {
            id: 'ArtMinKolic',
            header: 'Min Kolicina',
            accessorKey: 'ArtMinKolic',
        },
        {
            id: 'Stanje Paleta',
            header: 'Stanje Paleta',
            cell: ({row}) => {
                // Format the value as a decimal with 2 decimal places
                const formattedValue = parseFloat(row.original.total_palete || 0).toFixed(2);
                return (
                    <span>
                        {row.original.ArtRoditelj === 0
                            ? formattedValue
                            : row.original.total_palete}
                    </span>
                );
            },
        },
        {
            id: 'kategorija',
            header:
                'kategorija',
            accessorKey:
                'kategorija.KatNaziv',
        }
        ,
        {
            id: 'ArtTrgRoba',
            header:
                'Tr Roba',
            accessorKey:
                'ArtTrgRoba',
            cell:
                ({row}) => (row.original.ArtTrgRoba === 0 ? <div>Ne</div> : <div>Da</div>),
        }
        ,
        {
            header: 'Akcija',
            accessorKey:
                'Akcija',
            cell:
                ({row}) => (
                    <TableActionButton
                        onDelete={() => onDelete(row.original.ArtId)}
                        onEdit={() => {
                            onEdit(row.original.ArtId)
                        }}
                    />
                ),
        }
        ,

    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })
    const onDelete = (id) => {
        deleteArtikli(id)
            .then((response) => {
                notifyToast(true, response.data.message)
                setparams({per_page: pagination.perPage, page: pagination.curPage})
            })
            .catch((error) => {
                notifyToast(false, error.data['message'])
            })
    }

    const navigate = useNavigate()

    const handleRekalkulacija = async () => {
        setLoading(true)

        const response = await rekalkulacijaArtikala();
        console.log(response)
        if (response.error === 0) {
            notifyToast(true, response.message)
        } else {
            notifyToast(false, response.message || 'An unexpected error occurred.')
        }

        setLoading(false)

    }
    const onEdit = (rowData) => {
        navigate(`/artikli/izmjena/${rowData}`)
    }

    return (
        <KTCard>
            <ToastContainer/>
            <Modal show={isModalOpen} onHide={() => setisModalOpen(false)}>
                <ModalHeader closeButton={true}/>
                <img src={image} alt=''/>
            </Modal>
            <Modal show={isSumaOpen} onHide={() => setisSumaOpen(false)}>
                <ModalHeader closeButton={true} className='h3'>
                    Suma: {suma}
                </ModalHeader>
            </Modal>
            <KTCardBody className='py-4'>
                <ArtikliFilteri onFilter={onFilter}/>
                <div className="separator mb-5"></div>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <button
                            className='btn btn-light-success me-3'
                            onClick={handleRekalkulacija}
                            disabled={loading}
                        >
                            {loading && <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>}
                            {!loading && <KTIcon iconName='calculator' className='fs-2'/>}
                            Rekalkulacija
                        </button>
                        <button
                            className='btn btn-light-primary me-3'
                            onClick={() => {
                                handleSuma()
                                setisSumaOpen(true)
                            }}
                        >
                            <KTIcon iconName='calculator' className='fs-2'/>
                            Suma
                        </button>
                        <ExportButton table={table} filename={'artikli'}/>
                        <Link to='/artikli/dodaj'>
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2'/>
                                Dodaj
                            </button>
                        </Link>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}
export default ArtikliLista
