/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {ParamsModelPrometArtikla, PrometArtiklaModel} from '../../models/PrometArtiklaModel'
import {PaginationModel} from '../../models/PaginationModel'
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import {deletePrometArtikla, getPrometArtikla} from '../../api/PrometArtikla'
import notifyToast from '../../components/Toast/Toast'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {Link, useNavigate} from 'react-router-dom'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import {Modal, ModalHeader, ToastContainer} from 'react-bootstrap'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import TablePagination from '../../components/TablePagination/TablePagination'
import moments from 'moment'
import PrometArtiklaFilteri from '../../components/PrometArtiklaFilteri/PrometArtiklaFilteri'

const PrometArtiklaLista = () => {
    const [data, setData] = useState<PrometArtiklaModel[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [params, setparams] = useState<ParamsModelPrometArtikla>({per_page: 10, page: 1})

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    const [suma, setSuma] = useState(0)
    const [isSumaOpen, setisSumaOpen] = useState(false)

    const [sumaUlaz, setSumaUlaz] = useState(0)
    const [isSumaOpenUlaz, setisSumaOpenUlaz] = useState(false)

    const handleSuma = () => {
        var sum = 0
        table.getRowModel().rows.map((row) =>
            row.getVisibleCells().map((cell) => {
                if (cell.column.id === 'Izlaz') {
                    // @ts-ignore
                    sum += parseFloat(cell.getValue() ?? '0')
                }
            })
        )
        setSuma(sum)
    }
    const handleSumaUlaz = () => {
        var sum = 0
        table.getRowModel().rows.map((row) =>
            row.getVisibleCells().map((cell) => {
                if (cell.column.id === 'Ulaz') {
                    // @ts-ignore
                    sum += parseFloat(cell.getValue() ?? '0')
                }
            })
        )
        setSuma(sum)
    }

    const updatePage = () => {
        setLoading(true)
        getPrometArtikla(params)
            .then((response) => {
                const data = response.data['data']
                setData(data)
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const onFilter = (filter: Partial<ParamsModelPrometArtikla>) => {
        setparams({
            per_page: pagination.perPage,
            page: 1,
            artikal: filter.artikal,
            datumDo: filter.datumDo,
            datumOd: filter.datumOd,
            uposlenik: filter.uposlenik,
        })
    }

    const columns = [
        {
            id: 'Id',
            header: 'Id',
            accessorKey: 'Id',
        },
        {
            id: 'Sifra',
            header: 'Šifra',
            accessorKey: 'artikal.ArtSifra',
        },
        {
            id: 'Artikal',
            header: 'Artikal',
            accessorKey: 'artikal.ArtNaziv',
        },

        {
            id: 'Ulaz',
            header: 'Ulaz',
            accessorKey: 'Ulaz',
        },
        {
            id: 'Izlaz',
            header: 'Izlaz',
            accessorKey: 'Izlaz',
        },
        {
            id: 'Datum',
            header: 'Datum',
            accessorFn: (row) => `${moments(row.Datum).format('DD-MM-YYYY')}`,
        },
        {
            id: 'Dokument',
            header: 'Dokument',
            accessorFn: (row) => `${row.vrsta_dokumenta.DokNaziv} ${row.VrstaDokumentaBroj}`,
        },

        {
            id: 'Uposlenik',
            header: 'Uposlenik',
            accessorKey: 'uposlenik.UposlenikImePrezime',
        },

        {
            header: 'Akcija',
            accessorKey: 'Akcija',
            cell: ({row}) => (
                <TableActionButton
                    onDelete={() => onDelete(row.original.Id)}
                    onEdit={() => {
                        onEdit(row.original.Id)
                    }}
                />
            ),
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })
    const onDelete = (id) => {
        deletePrometArtikla(id)
            .then((response) => {
                notifyToast(true, response)
                setparams({per_page: pagination.perPage, page: pagination.curPage})
            })
            .catch((error) => {
                notifyToast(false, error.data['message'])
            })
    }
    const navigate = useNavigate()

    const onEdit = (rowData) => {
        navigate(`/artikli/promet/izmjena/${rowData}`)
    }
    const formatNumber = (number, locales = 'bs-BA', maximumFractionDigits = 2) => {
        return new Intl.NumberFormat(locales, {
            maximumFractionDigits: maximumFractionDigits,
            minimumFractionDigits: maximumFractionDigits // This ensures that we always have two decimal places
        }).format(number);
    };
    return (
        <KTCard>
            <ToastContainer/>
            <Modal show={isSumaOpen} onHide={() => setisSumaOpen(false)}>
                <ModalHeader closeButton={true} className='h3'>
                    Suma: {formatNumber(suma)}
                </ModalHeader>
            </Modal>
            <Modal show={isSumaOpenUlaz} onHide={() => setisSumaOpenUlaz(false)}>
                <ModalHeader closeButton={true} className='h3'>
                    Suma: {formatNumber(suma)}
                </ModalHeader>
            </Modal>

            <KTCardBody className='py-4'>
                <PrometArtiklaFilteri onFilter={onFilter}/>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <button
                            className='btn btn-light-primary me-3'
                            onClick={() => {
                                handleSumaUlaz()
                                setisSumaOpenUlaz(true)
                            }}
                        >
                            <KTIcon iconName='calculator' className='fs-2'/>
                            Suma Ulaz
                        </button>
                        <button
                            className='btn btn-light-primary me-3'
                            onClick={() => {
                                handleSuma()
                                setisSumaOpen(true)
                            }}
                        >
                            <KTIcon iconName='calculator' className='fs-2'/>
                            Suma Izlaz
                        </button>
                        <ExportButton table={table} filename={'promet_artikla'}/>
                        <Link to='/artikli/promet/dodaj'>
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2'/>
                                Dodaj
                            </button>
                        </Link>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}

export default PrometArtiklaLista
