import axios, {AxiosResponse, AxiosError} from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {RezervacijeRolni} from '../models/RezervacijeRolni'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/rezervacije`
const API_EXTEND = `${API_URL}/rezervacije/produziSelektiraneRezervacije`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getRezervacije(
    pageNo: number,
    perPage: number,
    filters?: { uposlenik?: string; boja?: string }
) {
  // Initialize the base params
  const params: any = { per_page: perPage, page: pageNo };

  // Add filters to params if they exist
  if (filters?.uposlenik) {
    params.uposlenik = filters.uposlenik;
  }
  if (filters?.boja) {
    params.boja = filters.boja;
  }

  // Make the API request with params
  return await axios.get<Array<RezervacijeRolni>>(API, {
    headers: headers,
    params: params,
  });
}


export async function deleteRezervacija(id: number) {
  try {
    const response = await axios.delete(`${API}/${id}`, { headers: headers });

    if (response.data?.error === 0) {
      return response;
    } else {
      // Logical API error, throw to handle it in the catch block
      throw new Error(response.data?.message || 'Failed to delete reservation.');
    }
  } catch (error) {
    // Check if the error is an AxiosError
    if (axios.isAxiosError(error) && error.response) {
      // Safely extract the error message from the Axios response
      const errorMessage = error.response.data?.message || 'An unexpected error occurred';
      throw new Error(errorMessage); // Re-throw for the caller to handle
    } else {
      // Handle non-Axios errors
      throw new Error(error instanceof Error ? error.message : 'An unexpected error occurred');
    }
  }
}

export async function updateRezervacija(Data: any, id: number) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function createRezervacije(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function extendRezervacija(ids: number[], brojDana: number) {
  try {
    const response = await axios.post(
        API_EXTEND,
        { ids, brojDana }, // Include the required fields
        { headers } // Include any necessary headers
    );

    if (response.data.error === 0) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Failed to extend reservations.');
    }
  } catch (error) {
    console.error('Error extending reservations:', error);
    throw error; // Re-throw the error for the caller to handle
  }
}
