/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react'
import {AktivnePaleteModel, ParamsModelAktivnePalete} from '../../models/AktivnePaleteModel'
import {PaginationModel} from '../../models/PaginationModel'
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import {getAktivniPalete} from '../../api/aktivnePalete'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import ArtikliDropdown from '../../components/ArtikliDropdown/ArtikliDropdown'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import TablePagination from '../../components/TablePagination/TablePagination'
import {Modal, ModalHeader} from 'react-bootstrap'
import AktivnePaletaQrCode from '../../components/QrCodeGenerator/AktivnePaletaQrCode'

const AktivnePalete = () => {
    const [data, setData] = useState<AktivnePaleteModel[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [params, setparams] = useState<ParamsModelAktivnePalete>({per_page: 10, page: 1})

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')
    const [suma, setSuma] = useState(0)
    const [isSumaOpen, setisSumaOpen] = useState(false)

    // Sum of ArtPaletaTrenutnoStanje.
    const handleSuma = () => {
        var sum = 0
        table.getRowModel().rows.map((row) =>
            row.getVisibleCells().map((cell) => {
                if (cell.column.id === 'Trstanje') {
                    // @ts-ignore
                    sum += parseFloat(cell.getValue() ?? '0')
                }
            })
        )
        setSuma(sum)
    }

    // Set cell color according to condtions.
    const getCellColor = (cell, row) => {
        // Color for Raspoloživost
        if (cell.column.id === 'Raspolozivost') {
            return cell.getValue() === 'Raspoloživ' ? '#008000' : '#FF0000.'
        }

        // Color default
        return '#FFFFFF'
    }

    const updatePage = () => {
        setLoading(true)
        getAktivniPalete(params)
            .then((response) => {
                const data = response.data['data']
                setData(data)
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const columns = [
        {
            id: 'Sifra',
            header: 'Sifra',
            accessorKey: 'artikal.ArtSifra',
        },
        {
            id: 'Artikal',
            header: 'Artikal',
            accessorKey: 'artikal.ArtNaziv',
        },
        {
            id: 'Paletabr',
            header: 'Paleta br',
            accessorKey: 'ArtPaletaId',
        },

        {
            id: 'Ulaz',
            header: 'Ulaz',
            accessorKey: 'ArtPaletaUlaz',
        },
        {
            id: 'Trstanje',
            header: 'Tr.stanje',
            accessorKey: 'ArtPaletaTrenutnoStanje',
        },
        {
            id: 'Jedinicamjere',
            header: 'Jedinica mjere',
            accessorKey: 'jedinica_mjere.JmOznaka',
        },
        {
            id: 'Kodboje',
            header: 'Kodboje',
            accessorKey: 'kodboje.KodBojeNaziv',
        },
        {
            id: 'Dokument',
            header: 'Dokument',
            accessorKey: 'dokument.DokNaziv',
        },
        {
            id: 'Lokacija',
            header: 'Lokacija',
            accessorKey: 'ArtPaletaLokacija',
        },
        {
            id: 'Pdf',
            header: 'Pdf',
            cell: ({row}) => <AktivnePaletaQrCode data={row.original}/>,
        },
        {
            id: 'Raspolozivost',
            header: 'Raspoloživost',
            accessorKey: 'ArtPaletaRaspolozivost',
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })
    const formatNumber = (number, locales = 'bs-BA', maximumFractionDigits = 2) => {
        return new Intl.NumberFormat(locales, {
            maximumFractionDigits: maximumFractionDigits,
            minimumFractionDigits: maximumFractionDigits // This ensures that we always have two decimal places
        }).format(number);
    };
    return (
        <KTCard>
            <ToastContainer/>
            <Modal show={isSumaOpen} onHide={() => setisSumaOpen(false)}>
                <ModalHeader closeButton={true} className='h3'>
                    Suma: {formatNumber(suma)}
                </ModalHeader>
            </Modal>
            <KTCardBody className='py-4'>
                <div className='col-3 mb-6'>
                    <h3 className="fw-bolder mt-3 mb-3">Odaberi artikal</h3>
                    <ArtikliDropdown onChange={(name, value) => setparams({...params, artikal: value})}/>
                </div>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <button
                            className='btn btn-light-primary me-3'
                            onClick={() => {
                                handleSuma()
                                setisSumaOpen(true)
                            }}
                        >
                            <KTIcon iconName='calculator' className='fs-2'/>
                            Suma
                        </button>
                        <ExportButton table={table} filename={'aktivne-palete'}/>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}

export default AktivnePalete
